import React from 'react';

const ListTodayStockData = ({ dataStock, data, mapStockName }) => {
  const formatDate = (dateTimeString) => {
    const dateTime = new Date(dateTimeString);

    const day = String(dateTime.getDate()).padStart(2, '0');
    const month = String(dateTime.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = dateTime.getFullYear();
    const hours = String(dateTime.getHours()).padStart(2, '0');
    const minutes = String(dateTime.getMinutes()).padStart(2, '0');

    return `${day}-${month}-${year}`;
  };

  const formatMoney = (number, decimalPlaces = 2) => {
    const fixedNumber = number.toFixed(decimalPlaces);
    const [integerPart, decimalPart] = fixedNumber.split('.');
    const integerWithCommas = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    const formattedNumber = `${integerWithCommas}.${decimalPart}`;

    return formattedNumber;
  };


  return (
    <>
      {dataStock.map((item) => (
        <div
          key={item._id}
          className='index-col px-2 mt-3 mb-3 border-end d-flex'
        >
          <div className='arrow'>
            <div
              className={item.priceData[0].status === 'up' ? 'up' : 'down'}
            />
          </div>
          <div className='box-number'>
            <div className='title'>{mapStockName(item.stockName)}</div>
            <div className='d-flex'>
              <div className='txt-n'>{formatMoney(item.priceData[0].price)}</div>

              <div
                className={
                  item.priceData[0].status === 'up' ? 'change-u' : 'change-d'
                }
              >
                <p
                  className='m-0 text-end index-change'
                  style={{ lineHeight: '1.3' }}
                >
                  {item.priceData[0].diff}
                </p>
                <p className='m-0 text-end' style={{ lineHeight: '1.3' }}>
                  {item.priceData[0].per}%
                </p>
              </div>
            </div>
            <div className='text-end mt-2' style={{ fontSize: '90%' }}>
              {/* Last Update: {formatDate(item.priceData[0].time)} */}
              Last Update: {formatDate(data.dateString) + ' ' + data.timeHHMM}
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default ListTodayStockData;
